// IMPORTS
@import (once) "/node_modules/swiper/swiper.less";
@import (once) "/node_modules/swiper/modules/navigation.less";
@import (once) "/node_modules/swiper/modules/pagination.less";
@import (once) "/node_modules/swiper/modules/autoplay.less";

@import (once) "settings.less";

// GENERAL
body {
    overflow-x: hidden;
    background-color: #2e1204;
}

.body-bg-img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    margin-left: 62px;
}

.goshop-modal-content {
    display: none;
}

#container {
    min-height: 100vh;
    min-height: -webkit-fill-available;

    // IMPORTANT
    display: flex;
    flex-direction: column;

    > *:not(.inner) {
        width: 100%;
    }

    #footer {
        margin-top: auto;
    }
}

// SHOP-HEADER
.header-sentinel {
    position: absolute;
    top: 0;
    left: 0;
}

.privacy-sentinel {
    height: 0;
    clear: both;
}

.custom-top-bar {
    position: relative;
    color: #f5f5f5;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    padding: 10px 0;

    &:after {
        content: '';
        .center-lr();
        top: 0;
        width: 101vw;
        height: 100%;
        z-index: -1;
        background-color: @light-bg;
    }
}

@media (min-width: 1280px) {
    .shop-header {
        margin-bottom: 32px;
    }
}

.shop-header-container {
    display: flex;
    align-items: center;
    height: 152px;
    justify-content: space-between;
}

// LOGO
.logo {
    display: flex;

    img {
        height: 108px;
        width: auto;
    }
}

.shop-header-main-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 24px;
}

.shop-header-small-info {
    display: flex;
    gap: 24px;
    .line-height();

    a {
        color: @font-color;
        display: flex;
        gap: 12px;

        &:before {
            font-family: icomoon;
            font-size: 20px;
        }

        &:hover {
            color: white;
        }
    }

    a[data-wishlist-count] {

        &:before {
            content: '\e90f';
        }
    }
}

a.header-email:before {
    content: '\e910';
}

// HEADER ICONS
.header-tools-wrapper {
    display: flex;
    gap: 31px;
}

@media (min-width: 1280px) {
    .tree-trigger {
        display: none;
    }

    .tree-canvas {
        display: none;
    }

    #cart-box,
    #wishlist-box, 
    a.log-button {
        position: relative;
        padding-left: 48px;
        display: flex;
        flex-direction: column;

        &:before {
            font-family: icomoon;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            line-height: 1;
            font-size: 36px;
            color: @main-color;
        }

        em {
            display: none;
        }

        span,
        b {
            font-family: @sec-font-family;
            text-transform: uppercase;
            color: white;
            font-size: 18px;
            line-height: 23px;
        }

        &:hover {

            span,
            b {
                color: @main-color;
            }
        }
    }

    #cart-box {

        &:before {
            content: '\e912';
        }

        i {
            position: absolute;
            display: none;
        }

        b {
            text-transform: none;
        }
    }

    #wishlist-box {
        display: none;

        &:before {
            content: '\e809';
        }

        &[data-wishlist-count="0"]:after {
            display: none;
        }

        &:after {
            content: attr(data-wishlist-count);
            display: none;
        }
    }

    a.log-button {

        &:before {
            content: '\e913';
        }
    }
}

// SEARCH ENGINE
.search-engine-trigger,
.search-engine-close {
    display: none;
}

// MAIN BAR
#main-bar {
    display: flex;
}

.mainmenu {
    flex-grow: 1;
    .gradient-brown();
    display: flex;
    justify-content: space-evenly;
    border-top-left-radius: var(--borderRadius);
    border-bottom-left-radius: var(--borderRadius);

    .selected a {
        color: white;
    }

    a {
        height: 58px;
        color: @main-color;
        font-family: @sec-font-family;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: @main-color;
        font-size: 18px;
        font-weight: 600;

        &:hover {
            color: white;
        }
    }
}

@media (min-width: 1280px) {
    #search-engine {
        position: relative;
        width: 370px;
        z-index: 100;
        flex-shrink: 0;

        &.show {

            #suggestions {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                transition: all .2s ease-in-out;

                .suggestion-last-phrases {
                    display: none;
                }
            }
        }

        &.show-with-last-phrases {

            &.no-products .no-products-info,
            a.suggestions-advanced-search {
                display: none;
            }

            #suggestions {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                transition: all .2s ease-in-out;

                .suggestion-result-categories,
                .suggestion-result-producers,
                .suggestion-result-products {
                    display: none;
                }
            }

            a.suggestions-advanced-search {
                display: block;
            }
        }

        &.no-products {

            .no-products-info {
                display: block;

                & + .suggestion-column-wrapper .suggestion-left-column:not(.hidden) {
                    margin-top: 10px;
                }
            }
        }

        input[type="text"] {
            width: 100%;
            height: 58px;
            padding: 0 76px 0 25px;
            .gradient-maincolor();
            color: #2e1204;
            font-size: 16px;
            font-family: @main-font-family;
            border: 0;
            border-radius: 0;
            border-top-right-radius: var(--borderRadius);
            border-bottom-right-radius: var(--borderRadius);

            &::-webkit-input-placeholder {
                color: #2e1204;
            }
            &::-moz-placeholder {
                color: #2e1204;
            }
            &:-ms-input-placeholder {
                color: #2e1204;
            }
            &:-moz-placeholder {
                color: #2e1204;
            }
        }

        button {
            position: absolute;
            top: 0;
            right: 0;
            width: 76px;
            height: 58px;
            color: white;
            font-size: 22px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:after {
                content: '\e911';
                font-family: icomoon;
            }

            &:hover {
                color: #2e1204;
            }
        }
    }
}

// SUGGESTIONS
#suggestions {
    position: absolute;
    top: ~"calc(100% + 5px)";
    right: 0;
    background-color: @light-bg;
    width: 720px;
    padding: 22px;
    .shadow();
    opacity: 0;
    visibility: hidden;
    border-radius: var(--borderRadius);
    display: flex;
    flex-wrap: wrap;
    transform: translateY(-10px);

    .no-products-info {
        display: none;
        font-size: 18px;
        font-weight: bold;
        color: @header-color;
        width: 100%;
    }

    &.loading {

        > *:not(.suggestions-loading) {
            display: none;
        }

        .suggestions-loading {
            display: flex;
        }
    }
}

.suggestions-loading {
    height: 200px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: none;

    span {
        border-radius: 50%;
        border: 4px solid @border-color;
        border-top-color: @header-color;
        animation: spin .5s infinite linear;
        transform: translateZ(0) rotate(0deg);
        transform-origin: center;
        -webkit-transform-style: preserve-3d;
        width: 100px;
        height: 100px;
    }
}

.suggestion-column-wrapper {
    display: flex;
    width: 100%;
}

.suggestion-left-column {
    width: 33%;
    flex-shrink: 0;

    &.hidden {
        display: none;
    }
}

.suggestion-result-categories,
.suggestion-result-producers,
.suggestion-result-products,
.suggestion-last-phrases {

    &.hidden {
        display: none;
    }

    &:before {
        content: attr(data-label);
        display: block;
        color: @header-color;
        font-weight: bold;
        font-size: 18px;
    }
}

.suggestion-result-categories,
.suggestion-result-producers {
    padding-right: 30px;

    a {
        display: inline-block;
        padding: 2px 0;
        color: @font-color;

        &:hover {
            text-decoration: underline;
        }
    }
}

.suggestion-last-phrases {
    width: 100%;

    span {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 5px 0;

        &:before {
            content: '\e802';
            font-family: icomoon;
            color: @header-color;
            float: left;
            width: 35px;
            font-size: 18px;
            flex-shrink: 0;
        }

        &:hover {
            color: @header-color;
        }
    }

    li {
        display: flex;
        align-items: center;

        &.hidden {
            display: none;
        }
    }

    .delete-hint-suggestion {
        margin-left: auto;
        font-style: normal;
        color: @header-color;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 11px;
        cursor: pointer;

        &:after {
            content: '\e803';
            font-family: icomoon;
        }

        &:hover {
            color: @error-color;
        }
    }
}

.suggestion-result-categories {
    margin-bottom: 22px;
}

.suggestion-result-products {
    flex-grow: 1;
}

.suggestion-product {
    margin-top: 10px;

    &:hover {
        
        b {
            text-decoration: underline;
        }
    }

    a {
        display: block;
        position: relative;
        min-height: 60px;
        padding-left: 80px;
    }

    .size-custom {
        position: absolute;
        top: 0;
        left: 0;
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        max-width: 100%;
        max-height: 100%;
        align-self: center;
    }

    b {
        color: @header-color;
        display: block;

    }

    span {
        color: @font-color;
    }
}

a.suggestions-advanced-search {
    width: 100%;
    margin-top: 12px;
    color: @header-color;

    &:hover {
        text-decoration: underline;
    }
}

// SIDEBAR
.sidebar {
    display: flex;
    flex-direction: column;
    gap: 28px;
}

#tree > img {
    display: none;
}

// TREE
@media (min-width: 1280px) {
    .cms-rwd,
    .tree-header {
        display: none;
    }

    #tree {
        background-color: @light-bg;
        border-radius: var(--borderRadius);
        padding: 7px 15px 18px;
        display: flex;
        flex-direction: column;
        position: relative;

        > img {
            display: block;
            position: absolute;
            width: auto;
            height: auto;
            top: 0;
            right: 0;
            z-index: 0;
        }

        &:before {
            content: 'Kategorie';
            font-size: 18px;
            font-weight: 600;
            font-family: @sec-font-family;
            padding: 14px 0;
            color: white;
        }

        button { 
            display: none;
        }

        > nav {
            position: relative;
            z-index: 1;
        }

        li {

            &.downarrow {

                > a {

                }

                > ul {
                    display: block;
                }
            }
        
            ul {
                display: none;
            }
        }

        li.t0 {

            &:not(:last-child),
            &.parent.downarrow > a {
                border-bottom: 1px dashed #89746e;
            }

            > a {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 8px 0;
                color: white;
            }

            ul {
                padding: 6px 0;
            }
        }

        a {
            position: relative;

            &:hover {
                color: @main-color !important;
            }
        }

        li:not(.t0) {

            a {
                display: block;
                color: @font-color;
                padding: 3px 0;
            }
        }

        li.t1 > a {
            padding-left: 15px;
        }

        li.t2 > a {
            padding-left: 30px;
        }

        li.t3 > a {
            padding-left: 45px;
        }

        li.selected > a {
            color: @main-color;
            font-weight: bold;
        }

        small {
            color: #bebebe !important;
            font-weight: normal !important;
            font-size: 14px;
        }
    }
}

.side-gift-occasions {
    position: relative;
    z-index: 100;

    &:hover {

        > span {

            &:after {
                opacity: 1;
            }
        }

        .wrapper {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
        }
    }

    > span {
        font-size: 18px;
        font-weight: 600;
        font-family: @sec-font-family;
        color: white;
        border-radius: var(--borderRadius);
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 18px 15px;
        cursor: pointer;

        &:before,
        &:after {
            content: '';
            z-index: -1;
            position: absolute;
            inset: 0;
            transition: opacity .2s ease-in-out;
        }

        &:before {
            .gradient-maincolor();
        }

        &:after {
            .gradient-brown();
            opacity: 0;
        }

        i {
            font-weight: normal;
            font-style: normal;
            line-height: 1px;

            &:after {
                content: '\e90e';
                font-family: icomoon;
            }
        }
    }

    .wrapper {
        position: absolute;
        top: 0;
        left: calc(100% + 5px);
        width: calc(1260px - 275px);
        background-color: @light-bg;
        .grid();
        grid-template-columns: repeat(6, minmax(0, 1fr));
        gap: 40px;
        padding: 40px 34px;
        border-radius: var(--borderRadius);
        .shadow();
        opacity: 0;
        visibility: hidden;
        transition: all .2s ease-in-out;
        transform: translateX(-10px);

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: -5px;
            width: 5px;
            height: 100%;
            background-color: transparent;
        }

        a {
            display: flex;
            flex-direction: column;
            text-align: center;
            gap: 10px;

            &:hover {

                b {
                    text-decoration: underline;
                }
            }
        }

        figure {
            aspect-ratio: 120 / 90;
        }

        img {
            display: block;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        b {
            color: white;
            font-size: 14px;
            line-height: 18px;
        }
    }
}

// SIDEBAR TABS
.sidebar-tabs {
    border-radius: var(--borderRadius);
    overflow: hidden;
    background-color: @light-bg;
    padding: 18px 15px 12px;

    .products-list-tabs {

        > strong {
            display: block;
            color: white;
            font-family: @sec-font-family;
            font-weight: 600;
            font-size: 18px;
        }
    }

    nav {
        display: flex;
        gap: 24px;

        button {
            cursor: pointer;
            user-select: none;
            color: #9f8e89;
            font-family: @sec-font-family;
            font-weight: 600;
            font-size: 18px;

            &[aria-selected="true"] {
                color: white;
            }
        }
    }

    .products-inner {

    }
}

// SIDEBAR PRODUCTS
.sidebar-product {
    display: flex;
    align-items: center;
    padding: 17px 0;
    border-bottom: 1px dashed #89746e;
    position: relative;

    &:last-child {
        border-bottom: 0;
    }

    > div {

        &:nth-child(1) {
            width: 60px;
            margin-right: 19px;
            flex-shrink: 0;
        }

        &:nth-child(2) {
            flex-grow: 1;
        }
    }

    &:hover .sidebar-product-name {
        text-decoration: underline;
    }
}

.sidebar-product-image {
    width: 100%;
    height: 80px; 
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        max-width: 100%;
        max-height: 100%;
        align-self: center;
        width: auto;
        height: auto;
    }
}

a.sidebar-product-name {
    font-size: 16px;
    overflow: hidden;
    color: #f7cd88;
    font-weight: normal;
    display: block;
    line-height: 1.2;

    &:first-letter {
        text-transform: uppercase;
    }

    &:after {
        content: '';
        position: absolute;
        inset: 0;
    }
}

.sidebar-product-price {
    display: block;
    margin-top: 3px;

    b,
    del {
        font-family: @sec-font-family; 

        &:after {
            content: ' zł';
        }
    }

    b {
        font-weight: 700;
        color: white;
        font-size: 18px;
    }

    del {
        color: #999999;
        margin-left: 8px;
        font-size: 14px;
    }
}

// SIDE STATIC BANNER
.side-static-banner {
    display: flex;
    flex-direction: column;
    gap: 28px;

    a {
        overflow: hidden;
        border-radius: var(--borderRadius);
        aspect-ratio: 270 / 130;
        position: relative;

        &:hover img {
            transform: scale(1.04);
        }
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1);
        transition: transform .2s ease-in-out;
    }

    b {
        color: #fefefe;
        font-family: @sec-font-family;
        font-weight: normal;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 11px;
        background-color: rgba(73,42,32,.9);
        width: fit-content;
        text-align: center;
        padding: 4px 10px 8px;
        min-width: 210px;
    }
}

// PRODUCTS
@keyframes productImageOpacity {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.products-list {
    .grid();
    grid-template-columns: repeat(var(--prodCols), minmax(0, 1fr));
    gap: var(--prodGap);
    width: 100%;
}

.product {
    position: relative;
    border: 1px solid #5f3b2f;
    padding: 14px 13px 0;
    .grid();
    grid-template-rows: auto 1fr;
    gap: 0;
    overflow: hidden;
    text-align: center;

    &.swiper-slide {
        box-sizing: border-box;

        .product-name {
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box !important;
            height: ~"calc(2 * (var(--fontSize) * var(--lineHeight)))";
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.product-image {
    aspect-ratio: 195 / 260;
    position: relative;
    transition: transform .1s ease-in-out;
    margin-bottom: 24px;
    overflow: hidden;
}

.product-description {
    color: #dddddd;
    font-size: 15px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(73,42,32,.9);
    padding: 21px 10px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
    transition: all .2s ease-in-out;

    span {
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        display: -webkit-box !important;
        max-height: ~"calc(4 * (15px * var(--lineHeight)))";
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

a.product-name {
    font-weight: normal;
    display: block;
    color: #f7cd88;
    transition: opacity .1s ease-in-out;
    z-index: 1; // IMPORTANT
    font-family: @sec-font-family;

    &:after {
        content: '';
        position: absolute;
        inset: 0;
    }

    &:first-letter {
        text-transform: uppercase;
    }
}

.product-bottom-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    gap: 15px;
    z-index: 1;
    height: 83px;
    padding-bottom: 8px;
}

.product-price {
    position: relative;

    b, del {
        font-family: @sec-font-family; 

        &:after {
            content: ' zł';
        }
    }

    b {
        font-size: 20px;
        color: white;
        position: relative;
        top: -2px;
    }

    del {
        position: absolute;
        font-size: 14px;
        color: #999999;
        left: 0;
        bottom: -14px;
        white-space: nowrap;
    }
}

.product-add,
.product-wishlist {
    height: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 24px;
    color: white;

    &:after {
        font-family: icomoon;
    }

    &:hover {
        color: @main-color;
    }
}

.product-add {
    color: white;

    &:after {
        content: '\e912';
    }

    &.disabled {
        display: none;
    }
}

.product-wishlist {
    margin-left: auto;

    &:after {
        content: '\e90f';
    }

    &:hover {

    }

    &.existing {
        color: @main-color;

        &:after {
            content: '\e914';
        }
    }
}

.product-badge {
    position: absolute;
    top: 13px;
    left: 13px;
    line-height: 23px;
    padding: 0 6px;
    color: white;
    background-color: @error-color;
    font-size: 14px;
    font-weight: bold;
}

@media (min-width: 1280px) {
    .product:hover {

        .product-description {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
            transition-delay: .2s;
        }

        .product-name {
            text-decoration: underline;
        }
    }
}

// BREADCRUMB
#breadcrumb {
    padding: 22px 0;
    border-bottom: 1px solid #5d4333;
    margin-bottom: 30px;

    ul {
        display: flex;
        flex-wrap: wrap;
    }

    li {
        display: flex;

        &:after {
            content: '-';
            margin: 0 7px;
        }

        &:last-child {

            &:after {
                display: none;
            }
        }
    }

    a,
    span {
        color: @font-color;
        display: block;
    }
}

.footer-shortcut {
    .grid();
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 21px 26px;
    padding-top: 7px;

    a {
        font-family: @sec-font-family;
        font-weight: 600;
        color: white;
        background-color: #482a20;
        padding: 12px 12px 13px;
        display: block;
        text-align: center;
        border-radius: var(--borderRadius);
        min-width: 185px;

        &:hover {
            background-color: #593428;
        }
    }
}

[data-type="mainpage"] #breadcrumb {
    display: none;
}

// LOADER
#loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.4);
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity .2s linear;
    pointer-events: none;

    &.show {
        opacity: 1;
        pointer-events: auto;
    }

    div {
        border-radius: 50%;
        border: 4px solid @border-color;
        border-top-color: @header-color;
        animation: spin .5s infinite linear;
        transform: translateZ(0) rotate(0deg);
        transform-origin: center;
        -webkit-transform-style: preserve-3d;
        width: 100px;
        height: 100px;
    }
}

@media (max-width: 600px) {
    .category-header-wrapper {
        display: contents;
    }

    .category-description {
        order: 2;
    }
}